@tailwind base;
// @font-face {
//   font-family: "Aleck Sans";
//   src: url("../fonts/ATT-Aleck-Sans-Regular.woff") format("woff"),
//     url("../fonts/ATT-Aleck-Sans-Regular.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Aleck Sans Black";
//   src: url("../fonts/ATT-Aleck-Sans-Black.woff") format("woff"),
//     url("../fonts/ATT-Aleck-Sans-Black.woff2") format("woff2");
// }
@font-face {
  font-family: "Aleck Sans Medium";
  src: url("../fonts/ATT-Aleck-Sans-Medium.woff") format("woff"),
    url("../fonts/ATT-Aleck-Sans-Medium.woff2") format("woff2");
}
html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  font-family: "Aleck Sans Medium", sans-serif;
  font-size: 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.filters-enter {
  opacity: 0.01;
}

.filters-enter.filters-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.filters-leave {
  opacity: 1;
}

.filters-leave.filters-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.reflect {
  transform: scaleX(-1);
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

@keyframes wiggle {
  0% {
    transform: rotate(4deg);
  }
  25% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle {
  animation: wiggle 1000ms 1 ease-in-out;
}

@tailwind components;
@tailwind utilities;
